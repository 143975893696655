import React from "react";
import Seo from "../components/seo";
import { Link } from "gatsby";
// import { StyledMobileFixButton } from "../components/enterpriseConf2021/styles/StyledEGConf";
// import Layout from "../components/enterpriseConf2022/layout";
import Layout from "../components/layout";
import EnterpriseConf2022 from "../components/enterpriseConf2022/EnterpriseConf2022";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/enterprise-conf-2022.png",
};
const canonicalUrl = "https://hasura.io/enterprisegraphql/";

const EnterpriseConf2022Index = props => (
  <Layout location={props.location} withoutHeader = {true}>
    <Seo
      title="Enterprise GraphQL Conference 2022"
      description="Learn how some of the world’s largest enterprises are using GraphQL to enable data-as-a-service and eliminate common data access and management challenges."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <EnterpriseConf2022 location={props.location}/>
  </Layout>
);

export default EnterpriseConf2022Index;

import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { format, utcToZonedTime } from "date-fns-tz";

import { StyledSubTitle2, StyledDesc3 } from "../shared/StyledTypography";
import { mq } from "../../globals/utils";
import { flexCenter } from "../shared/CommonStyled";
import { scrollToTop } from "../../utils/helpers";

const StyledSpeakerList = styled(props => <Link {...props} />)`
  background-color: #12171F;
  border-radius: 16px;
  position: relative;
  min-height: 400px;
  transition: all .4s ease-in-out;
  &:first-child:hover ~ &:nth-child(3n + 4) {
     transform: translateY(240px);
  }
  &:nth-child(2):hover ~ &:nth-child(3n + 2) {
    transform: translateY(240px);
  }
  &:nth-child(3):hover ~ &:nth-child(3n + 3) {
    transform: translateY(240px);
  }
  &:hover {
    .speakerImg {
      img {
        transform: scale(1) translateY(-60px);
      }
    }
    .speakerDetailsWrapper {
      top: 240px;
      z-index: 10;
      &.noHover {
        top: 0;
      }
    }
    &:first-child {

    }
  }
  .speakerImg {
    height: 320px;
    overflow: hidden;
    border-radius: 16px 16px 0 0;
    width: calc(100% - 2px);
    margin: 0 auto;
    img {
      transition: all .4s ease-in-out;
      height: 100%;
      object-fit: cover;
      object-position: top;
      width: 100%;
      transform: scale(1.1);
      border-radius: 16px 16px 0 0;
    }
  }
  .speakerDetailsWrapper {
    transition: all .4s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #12171F;
    border-radius: 13px;
    padding: 32px;
    display: grid;
    height: 100%;
    .alignSelfStart {
      align-self: start;
    }
    .alignSelfEnd {
      align-self: end;
    }
    .italic {
      font-style: italic;
    }
    .cencelledTag {
      color: #16A9C9;
    }
    .speakerBrand {
      padding-bottom: 42px;
      height: 90px;
      img {
        max-height: 45px;
      }
      &.speakerBrandSmall {
        img {
          max-height: 35px;
        }
      }
    }
    .textGradient {
      padding-bottom: 30px;
    }
    .fSize {
      font-size: 22px;
      line-height: 126%;
      font-weight: bold;
    }
  }
  .hideHover {
    display: flex;
    align-items: center;
    justify-content: center;
    .speakerBrand {
      padding: 0 40px;
      padding-bottom: 0;
      height: auto;
      img {
        max-height: 45px;
      }
    }
    .posBottom {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 40px;
    }
  }
  ${mq.between("lg", "xl")} {
    min-height: 440px !important;
    &:first-child:hover ~ &:nth-child(odd) {
      transform: none !important;
    }
    &:nth-child(2):hover ~ &:nth-child(even) {
      transform: none !important;
    }
    &:nth-child(3):hover ~ &:nth-child(odd) {
      transform: none !important;
    }
    &:first-child:hover ~ &:nth-child(3n + 4) {
       transform: translateY(210px) !important;
    }
    &:nth-child(2):hover ~ &:nth-child(3n + 2) {
      transform: translateY(210px) !important;
    }
    &:nth-child(3):hover ~ &:nth-child(3n + 3) {
      transform: translateY(210px) !important;
    }
    &:hover {
      .speakerImg {
        img {
          transform: scale(1) translateY(-70px) !important;
        }
      }
      .speakerDetailsWrapper {
        top: 210px !important;
        &.noHover {
          top: 0;
        }
      }
    }
    .speakerImg {
      height: 290px !important;
    }
  }
  ${mq.between("md", "lg")} {
    min-height: 380px;
    &:first-child:hover ~ &:nth-child(3n + 4) {
       transform: none;
    }
    &:nth-child(2):hover ~ &:nth-child(3n + 2) {
      transform: none;
    }
    &:nth-child(3):hover ~ &:nth-child(3n + 3) {
      transform: none;
    }
    &:first-child:hover ~ &:nth-child(odd) {
      transform: translateY(260px);
    }
    &:nth-child(2):hover ~ &:nth-child(even) {
      transform: translateY(260px);
    }
    &:nth-child(3):hover ~ &:nth-child(odd) {
      transform: translateY(260px);
    }
    .speakerDetailsWrapper {
      min-height: 370px;
      padding: 24px;
    }
    .speakerImg {
      height: 360px;
      width: calc(100% - 2px);
      margin: 0 auto;
    }
    &:hover {
      .speakerImg {
        img {
          transform: scale(1) translateY(-100px);
        }
      }
      .speakerDetailsWrapper {
        top: 260px;
        &.noHover {
          top: 0;
        }
      }
    }

  }
  ${mq.below.md} {
    min-height: 330px;
    &:first-child:hover ~ &:nth-child(3n + 4) {
       transform: none;
    }
    &:nth-child(2):hover ~ &:nth-child(3n + 2) {
      transform: none;
    }
    &:nth-child(3):hover ~ &:nth-child(3n + 3) {
      transform: none;
    }
    &:first-child:hover ~ &:nth-child(odd) {
      transform: none;
    }
    &:nth-child(2):hover ~ &:nth-child(even) {
      transform: none;
    }
    &:nth-child(3):hover ~ &:nth-child(odd) {
      transform: none;
    }
    &:hover {
      .speakerImg {
        img {
          transform: scale(1) translateY(0px);
        }
      }
    }
    .speakerImg {
      height: auto;
      overflow: visible;
      img {
        height: auto;
        transform: scale(1) translateY(0px);
      }
    }
    .speakerDetailsWrapper {
      position: static;
      z-index: 1000;
      padding: 20px;
      padding: 20px;
      padding-top: 32px;
      height: auto;
    }
  }
`;

const StyledSpeakerListDiv = styled.div`
  background-color: #12171F;
  border-radius: 16px;
  position: relative;
  min-height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  .speakerBrand {
    padding: 0px 40px;
    img {
      max-height: 45px;
    }
  }
  .posBottom {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 40px;
  }
  ${mq.between("lg", "xl")} {
    min-height: 380px;
  }
  ${mq.between("md", "lg")} {
    min-height: 336px;
  }
  ${mq.below.md} {
    min-height: 300px;
  }
`;

const timeZone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;

const localTimeZoneValue = format(utcToZonedTime(new Date(), timeZone), "z", {
  timeZone,
});

const localTimeZone = localTimeZoneValue == "GMT+5:30" ? "IST" : localTimeZoneValue;

const IndivSpeakerList = ({speakersList}) => {

  const refStartDate = new Date(speakersList.startTime);
  const refEndDate = new Date(speakersList.endTime);

  const dateValue = refStartDate.getDate().toString();

  const monthValue = format(refStartDate, "MMM");

  const yearValue = format(refStartDate, "yyyy");

  const timeValue = format(refStartDate, "h:mm a");

  const date = `${monthValue} ${dateValue}, ${yearValue}  | ${timeValue} (${localTimeZone})`;

  const [ confStatus, setConfStatus] = useState("yetToStart")
  const currentTime = new Date();

  useEffect(()=> {
    if(currentTime < refStartDate ) {
      setConfStatus("yetToStart");
    }
    if((currentTime > refStartDate) && (currentTime < refEndDate)) {
      setConfStatus("onGoing");
    }
    if(currentTime > refEndDate) {
      setConfStatus("completed");
    }
  }, [refStartDate]);
  const currentConfStatus = () => {
    if (confStatus === "completed") {
      return <StyledDesc3 variant="white" paddingBottom="pb8" fontWeight="font_bold">Completed</StyledDesc3>
    }
    if (confStatus === "onGoing") {
      return <StyledDesc3 variant="white" paddingBottom="pb8" fontWeight="font_bold" css={flexCenter}><div className="greenCircle smallCircle" />Live now</StyledDesc3>
    }
    return <StyledDesc3 variant="white" paddingBottom="pb8" fontWeight="font_bold">{date}</StyledDesc3>
  }
  return (
    <>
      {
        speakersList.comingSoon ? (
          <StyledSpeakerListDiv>
            <div className="speakerBrand">
              <img src={speakersList.brandLogo} alt="Brand" loading="lazy"/>
            </div>
            <StyledDesc3 className="posBottom" variant="white" fontWeight="font_bold">Coming Soon</StyledDesc3>
          </StyledSpeakerListDiv>
        ) : (
          <StyledSpeakerList to={`/enterprisegraphql/${speakersList.id}/`} onClick={scrollToTop}>
            {
              !speakersList.withoutSpeaker ? (
                <div className="speakerImg">
                  {
                    speakersList.multipleSpeakerImg ? (
                      <img src={speakersList.multipleSpeakerImg} alt={speakersList.multipleSpeakerName} loading="lazy"/>
                    ) : (
                      <img src={speakersList.speakerDetails[0].speakerImg} alt={speakersList.speakerDetails[0].speakerName} loading="lazy"/>
                    )
                  }
                </div>
              ) : null
            }
            <div className={"speakerDetailsWrapper" + ((speakersList.withoutSpeaker) ? " noHover" : "")}>
              <div className="alignSelfStart">
                <div className={"speakerBrand" + ((speakersList.withoutSpeaker) ? " speakerBrandSmall" : "")}>
                  <img src={speakersList.brandLogo} alt="Brand" loading="lazy"/>
                </div>
                {/*
                  speakersList.eventCancelled ? (
                    <StyledDesc3 className={((speakersList.eventCancelled) ? "cencelledTag" : "")} variant="white" paddingBottom="pb8" fontWeight="font_bold">{speakersList.dateTime}</StyledDesc3>
                  ) : (
                    <>
                      {currentConfStatus()}
                    </>
                  )
                */}

                <StyledSubTitle2 className="textGradient fSize" fontWeight="font_bold">{speakersList.title}</StyledSubTitle2>
              </div>
              <div className="alignSelfEnd">
                {
                  speakersList.multipleSpeakerName ? (
                    <>
                      <StyledDesc3 variant="white" fontWeight="font_bold">{speakersList.multipleSpeakerName}</StyledDesc3>
                      <StyledDesc3 variant="white">{speakersList.multipleSpeakersdesignation}</StyledDesc3>
                    </>
                  ) : (
                    <>
                      {
                        !speakersList.withoutSpeaker ? (
                          <>
                            <StyledDesc3 variant="white" fontWeight="font_bold">{speakersList.speakerDetails[0].speakerName}</StyledDesc3>
                            <StyledDesc3 variant="white">{speakersList.speakerDetails[0].designation}</StyledDesc3>
                          </>
                        ) : null
                      }
                    </>
                  )
                }
                {
                  speakersList.eventCancelled && (
                    <StyledDesc3 className="italic" variant="white">Due to last minute conflicts we are working on republishing at a later day.</StyledDesc3>
                  )
                }
              </div>
            </div>
          </StyledSpeakerList>
        )
      }
    </>
  );
};

export default IndivSpeakerList;

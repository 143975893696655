import React, { useState, useEffect, Fragment } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import StyledUserBrandWrapper from "../shared/StyledUserBrandWrapper";

import { scrollToTop } from "../common/Header/helper";
import { StyledDesc1, StyledDesc3 } from "../shared/StyledTypography";
import { flexCenter } from "../shared/CommonStyled";
import { Icon } from "../../globals/icons";
import { mq } from "../../globals/utils";

import venmo from "./images/venmo-white.svg";
// import optum from "./images/optum-white.svg";
import paypal from "./images/paypal-white.svg";
import sap from "./images/sap-white.svg";
import nike from "./images/nike-white.svg";
import sodexo from "./images/sodexo-white.svg";
import verizon from "./images/verizon-white.svg";
import ibm from "./images/ibm-white.svg";
import recapIcon from "./images/recap-icon.svg";

const attendeesBrandState = [
  {
    imgSrc: venmo,
    altText: "Venmo",
  },
  // {
  //   imgSrc: optum,
  //   altText: "Optum",
  // },
  {
    imgSrc: paypal,
    altText: "Paypal",
  },
  {
    imgSrc: sap,
    altText: "SAP",
  },
  {
    break: true,
  },
  {
    imgSrc: nike,
    altText: "Nike",
  },
  {
    imgSrc: sodexo,
    altText: "Sodexo",
  },
  {
    imgSrc: verizon,
    altText: "Verizon",
  },
  {
    imgSrc: ibm,
    altText: "ibm",
  },
];

const egc2021State = [
  {
    talkType: "KEYNOTE",
    title: "Build a GraphQL powered data mesh for your polyglot data",
    linkUrl: "/enterprisegraphql/2021/build-a-graphql-powered-data-mesh-for-your-polyglot-data/",
  },
  {
    talkType: "FIRESIDE CHAT",
    title: "Fireside Chat: GraphQL & the Data Mesh",
    linkUrl: "/enterprisegraphql/2021/graphql-the-data-mesh/",
  },
  {
    talkType: "TALK",
    title: "The Ghosts of Databases Past, Present and Future",
    linkUrl: "/enterprisegraphql/2021/the-ghosts-of-databases-past-present-and-future/",
  },
];

const StyledRecapWrapper = styled.div`
  .headerIcon {
    margin-right: 16px;
  }
  .egc2021Wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
    padding-top: 56px;
    .egc2021List {
      background: #12171f;
      box-shadow: 0px 1.46593px 4.39779px rgba(0, 0, 0, 0.2);
      border-radius: 16px;
      padding: 32px 24px;
      &:hover {
        .flexArrow {
          .rightArrow {
            transform: translateX(5px);
          }
        }
      }
      .flexArrow {
        display: flex;
        align-items: end;
        justify-content: space-between;
        .rightArrow {
          margin-left: 12px;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
  .egc2021Attendance {
    padding-top: 60px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 90px;
    .borLeftQuote {
      border-width: 2px;
      border-style: solid;
      border-image: linear-gradient(to bottom, #e8ffb8, #1eb4d4) 1 100%;
      border-right: 0;
      border-top: 0;
      border-bottom: 0;
      padding-left: 20px;
      color: #6b859d;
      width: 260px;
      align-self: end;
    }
  }
  ${mq.between("md", "xl")} {
    .egc2021Wrapper {
      grid-template-columns: 1fr 1fr;
    }
    .egc2021Attendance {
      grid-template-columns: 1fr;
      grid-gap: 40px;
    }
  }
  ${mq.below.md} {
    .egc2021Wrapper {
      grid-template-columns: 1fr;
    }
    .egc2021Attendance {
      grid-template-columns: 1fr;
      grid-gap: 40px;
    }
  }
`;
const StyledUserBrandWrapperEgc = styled(StyledUserBrandWrapper)`
  ${mq.between("md", "lg")} {
    .brandImg {
      max-width: 150px;
    }
  }
`;

export function useWindowSize() {
  const [windowWidth, setWindowSize] = useState(undefined);

  useEffect(() => {
    function handleResize() {
      setWindowSize(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowWidth;
}

const RecapLastYear = () => {
  const windowWidth = useWindowSize();
  const isMobile = windowWidth <= 767 ? true : false;
  return (
    <StyledSectionWrapper>
      <StyledContainerWrapper>
        <StyledRecapWrapper>
          <StyledDesc1 variant="white" fontWeight="font_500" css={flexCenter}>
            <img className="headerIcon" src={recapIcon} alt="icon" />
            Quick recap of last year
          </StyledDesc1>
          <div className="egc2021Wrapper">
            {egc2021State.map((egc21, index) => (
              <Link to={egc21.linkUrl} key={index} className="egc2021List" onClick={scrollToTop}>
                <StyledDesc3 variant="cyan_60" paddingBottom="pb4" fontWeight="font_600">
                  {egc21.talkType}
                </StyledDesc3>
                <div className="flexArrow">
                  <StyledDesc1 variant="white">{egc21.title}</StyledDesc1>
                  <Icon className="rightArrow" variant="arrow_right" size="md" color="white" />
                </div>
              </Link>
            ))}
          </div>
          <div className="egc2021Attendance">
            <StyledDesc3 className="borLeftQuote">
              EGC’21 saw attendance from CTOs, architects, leads and developers from some of the
              most influential brands in the world.
            </StyledDesc3>
            <StyledUserBrandWrapperEgc>
              {attendeesBrandState.map((brand, index) => (
                <Fragment key={index}>
                  {brand.break ? (
                    <>{!isMobile ? <div className="breakFlex"></div> : null}</>
                  ) : (
                    <div className="brandImg">
                      <img src={brand.imgSrc} alt={brand.altText} />
                    </div>
                  )}
                </Fragment>
              ))}
            </StyledUserBrandWrapperEgc>
          </div>
        </StyledRecapWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapper>
  );
};

export default RecapLastYear;

import React, { useState, useEffect } from "react";
import styled from "styled-components";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import StyledButton from "../shared/StyledButton";
import StyledVideoWrapper from "../shared/StyledVideoWrapper";

import {
  StyledDisplay3,
  StyledSubTitle1,
  StyledSubTitle2,
  StyledBody1,
  StyledDesc1,
  StyledDesc3,
} from "../shared/StyledTypography";
import { textCenter, justifyCenter, flexCenter } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

import speakerIcon from "./images/speaker-icon.svg";

const StyledVideoWrapperPadd = styled(StyledVideoWrapper)`
  padding-top: 24px;
  z-index: 2;
  position: relative;
`;

const StyledSectionWrapperPos = styled(StyledSectionWrapper)`
  position: relative;
  padding-bottom: 0;
  .leftIllu,
  .rightIllu {
    position: absolute;
    top: 50px;
    /* transform: translateY(-75%); */
    width: 250px;
  }
  .leftIllu {
    left: 0;
  }
  .rightIllu {
    right: 0;
  }
  @media (min-width: 1500px) {
    .leftIllu,
    .rightIllu {
      width: 350px;
      /* transform: translateY(-80%); */
    }
  }
  @media (min-width: 1280px) and (max-width: 1449px) {
    .leftIllu,
    .rightIllu {
      width: 320px;
    }
  }
  ${mq.between("lg", "xl")} {
    .leftIllu,
    .rightIllu {
      /* transform: translateY(-80%); */
    }
  }
  ${mq.between("md", "lg")} {
    .leftIllu,
    .rightIllu {
      width: 100px;
      /* transform: translateY(-100%); */
    }
  }
  ${mq.below.md} {
    .leftIllu,
    .rightIllu {
      display: none;
    }
  }
`;

const StyledEGCHeroBanner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 70px;
  z-index: 10;
  position: relative;
  .mW680 {
    /* max-width: 680px;
    margin: 0 auto; */
    z-index: 1;
    position: relative;
    .buttonWrapper {
      /* display: flex;
      align-items: center;
      justify-content: center; */
      padding-top: 32px;
      padding-bottom: 30px;
      .mWid {
        min-width: 200px;
        /* margin: 0 12px; */
      }
    }
  }
  .headerIcon {
    margin-right: 16px;
  }
  .videoThumbImg {
    z-index: 2;
    position: relative;
    cursor: pointer;
    margin-top: 24px;
    img {
      border-radius: 16px;
    }
  }
  .buildApiGrid {
    width: 92%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 100px;
    .textBlueColor {
      color: #1eb4d4;
    }
    .displayGrid {
      display: grid;
    }
    .borLeftQuote {
      border-width: 2px;
      border-style: solid;
      border-image: linear-gradient(to bottom, #e8ffb8, #1eb4d4) 1 100%;
      border-right: 0;
      border-top: 0;
      border-bottom: 0;
      padding-left: 20px;
      color: #6b859d;
      width: 230px;
      align-self: end;
    }
  }
  ${mq.between("md", "lg")} {
    grid-template-columns: 1fr;
    grid-gap: 50px;
    .mW680 {
      .buttonWrapper {
        /* padding-bottom: 90px; */
      }
    }
    .buildApiGrid {
      width: 100%;
      grid-gap: 60px;
    }
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
    .mW680 {
      .textGradient {
        font-size: 36px;
      }
      .buttonWrapper {
        /* padding-bottom: 60px; */
        flex-direction: column;
        .mWid {
          /* margin: 12px 0; */
        }
      }
    }
    .buildApiGrid {
      width: 100%;
      grid-template-columns: 1fr;
      grid-gap: 50px;
      .borLeftQuote {
        margin-top: 40px;
      }
    }
  }
  ${mq.below.sm} {
    .mfs {
      font-size: 13px;
    }
  }
`;

const HeroBanner = ({
  onFormOpen,
  currentBtnRef,
  onLogInFormOpen,
  currentLogInBtnRef,
  clearSubmissionNotFoundError,
  location,
  thumbImgRef,
}) => {
  const [isLocalEgc2022, setIsLocalEgc2022] = useState(false);
  // const [skipGating, setSkipGating] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchPromoCode = searchParams.get("aliId");
    if (!!searchPromoCode && searchPromoCode === "egc_2022_rec") {
      if (typeof window !== undefined) {
        window.localStorage.setItem("egc2022Consent", "true");
      }
    }
    if (typeof window !== undefined) {
      if ("localStorage" in window && window.localStorage && "getItem" in window.localStorage) {
        const egc2022Consent = window.localStorage.getItem("egc2022Consent");
        const egcLogIn2022Consent = window.localStorage.getItem("egcLogIn2022Consent");
        if (egc2022Consent) {
          setIsLocalEgc2022(true);
        }
        if(egcLogIn2022Consent) {
          setIsLocalEgc2022(true);
        }
      }
    }
  }, [location.search]);
  return (
    <StyledSectionWrapperPos>
      {/*<img
        className="leftIllu"
        src="https://graphql-engine-cdn.hasura.io/assets/main-site/egc2022/hero-left.png"
        alt="Illu"
        loading="lazy"
      />
      <img
        className="rightIllu"
        src="https://graphql-engine-cdn.hasura.io/assets/main-site/egc2022/hero-right.png"
        alt="Illu"
        loading="lazy"
      />*/}
      <StyledContainerWrapper>
        <StyledEGCHeroBanner>
          <div className="mW680">
            <StyledDisplay3
              className="textGradient"
              fontWeight="font_bold"
              paddingBottom="pb24"
            >
              Enterprise GraphQL Conference
            </StyledDisplay3>
            <StyledBody1 variant="white" fontWeight="font_400">
              Learn how some of the world’s largest enterprises are using GraphQL to enable
              data-as-a-service and eliminate common data access and management challenges.{" "}
            </StyledBody1>
            <div className="buttonWrapper">
              {
                !isLocalEgc2022 && (
                  <StyledButton
                    className="mWid"
                    variant="blue"
                    size="md"
                    onClick={() => {
                      clearSubmissionNotFoundError();
                      onLogInFormOpen();
                    }}
                    ref={currentLogInBtnRef}
                  >
                    Unlock recordings
                  </StyledButton>
                )
              }
            </div>
          </div>
          <div>
            <StyledDesc1 variant="white" fontWeight="font_500" css={flexCenter}>
              <img className="headerIcon" src={speakerIcon} alt="icon" />
              Watch this year’s Keynote
            </StyledDesc1>
            {
              isLocalEgc2022 ? (
                <StyledVideoWrapperPadd>
                  <div className="videoAspectRatio">
                    <iframe
                      loading="lazy"
                      title="GraphQL – the missing piece to realizing your enterprise platform vision"
                      src="https://player.vimeo.com/video/771023549"
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </div>
                </StyledVideoWrapperPadd>
              ) : (
                <div className="videoThumbImg"
                  onClick={() => {
                    clearSubmissionNotFoundError();
                    onLogInFormOpen();
                  }}
                  ref={thumbImgRef}
                >
                  <img src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1668501615/main-web/thumb-1_fdfyae.png" alt="Thumb" loading="lazy" />
                </div>
              )
            }
          </div>
          {/*
          <div className="buildApiGrid">
            <div className="displayGrid">
              <StyledSubTitle1 className="textBlueColor" fontWeight="font_500">
                The time spent in building APIs is over 20 hours a week for 40% of backend
                developers
              </StyledSubTitle1>
              <StyledDesc3 className="borLeftQuote">
                Postman’s State of the API Report for 2022
              </StyledDesc3>
            </div>
            <div>
              <StyledDesc1 variant="white">
                Data access and management remains a top priority for businesses, and many leading
                organizations are turning to GraphQL to solve this challenge. GraphQL is reshaping
                the data landscape and enabling newer and more efficient architectures for making
                data access more self-serve across analytics and transactional workloads. Join us to
                hear from organizations leading this charge.
                <br />
                <br />
                Goldman Sachs, United Healthcare, Linkedin and Mercedes will share how they
                approached their data platform requirements with GraphQL, the challenges they faced
                and overcame, and the business outcomes they are driving.
              </StyledDesc1>
            </div>
          </div>
          */}
        </StyledEGCHeroBanner>
      </StyledContainerWrapper>
    </StyledSectionWrapperPos>
  );
};

export default HeroBanner;

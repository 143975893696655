import React from "react";
import styled from "styled-components";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import IndivSpeakerList from "./IndivSpeakerList";

import { allEgc2022StateDetails } from "./AllEgc2022State.js";
import { StyledDesc1 } from "../shared/StyledTypography";
import { flexCenter } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

import videoIcon from "./images/video-icon.svg";

const StyledSectionWrapperPos = styled(StyledSectionWrapper)`
  position: relative;
  z-index: 1;
  .leftGlow {
    position: absolute;
    left: 0;
    top: -300px;
    width: 500px;
    z-index: -1;
  }
`;

// const StyledContainerWrapperWd = styled(StyledContainerWrapper)`
//   max-width: 1320px;
// `;
const StyledSpeakersWrapper = styled.div`
  .headerIcon {
    margin-right: 16px;
  }
  .speakersListWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
    padding-top: 56px;
  }
  ${mq.between("lg", "xl")} {
    .speakersListWrapper {
      grid-template-columns: 1fr 1fr 1fr !important;
    }
  }
  ${mq.between("md", "lg")} {
    .speakersListWrapper {
      grid-template-columns: 1fr 1fr;
    }
  }
  ${mq.below.md} {
    .speakersListWrapper {
      grid-template-columns: 1fr;
    }
  }
`

const Speakers = () => {
  const speakerWrapper = allEgc2022StateDetails.map((list, index) => (
    <IndivSpeakerList key={index} speakersList={list} />
  ))
  return (
    <StyledSectionWrapperPos>
      <img className="leftGlow" src="https://graphql-engine-cdn.hasura.io/assets/main-site/egc2022/left-glow.png" alt="Reg Illu" loading="lazy" />
      <StyledContainerWrapper>
        <StyledSpeakersWrapper>
          <StyledDesc1 variant="white" fontWeight="font_500" css={flexCenter}>
            <img className="headerIcon" src={videoIcon} alt="icon" />
            EGC’22 Recordings
          </StyledDesc1>
          <div className="speakersListWrapper">
            {speakerWrapper}
          </div>
        </StyledSpeakersWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapperPos>
  );
};

export default Speakers;

import React, { useState, useEffect, useRef } from "react";

import Header from "../common/Header";
import HeroBanner from "./HeroBanner";
import RegistrationForm from "./RegistrationForm";
import LogInForm from "./LogInForm";
import Speakers from "./Speakers";
import RecapLastYear from "./RecapLastYear";
// import FooterReg from "./FooterReg";
import CopyWriterWithSidebar from "../common/CopyWriterWithSidebar";

import { StyledDarkThemeWrapper, StyledEGCRegistrationFormWrapper } from "./styles/StyledComponent";
import { translateXZero } from "../shared/CommonStyled";

const EnterpriseConf2022 = ({ location }) => {
  const regFormRef = useRef(null);
  const logInFormRef = useRef(null);
  const regHeroBannerBtnRef = useRef(null);
  const logInHeroBannerBtnRef = useRef(null);
  // const regNavBtnMobileRef = useRef(null);
  const thumbImgRef = useRef(null);
  const regFooterBtnRef = useRef(null);
  const [isRegFormOpen, setIsRegFormOpen] = useState(false);
  const [isLogInFormOpen, setIsLogInFormOpen] = useState(false);
  const [isEmailSubmissionNotFoundError, setIsEmailSubmissionNotFoundError] = useState(false);
  const [isLocalEgc2022, setIsLocalEgc2022] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (typeof window !== undefined) {
      if ("localStorage" in window && window.localStorage && "getItem" in window.localStorage) {
        const egc2022Consent = window.localStorage.getItem("egc2022Consent");
        const egcLogIn2022Consent = window.localStorage.getItem("egcLogIn2022Consent");
        if (egc2022Consent) {
          setIsLocalEgc2022(true);
        }
        if(egcLogIn2022Consent) {
          setIsLocalEgc2022(true);
        }
      }
    }
  }, [location.search]);

  const closeOpenModalsAfterFiveSeconds = () => {
    setTimeout(() => {
      setIsRegFormOpen(false);
      setIsLogInFormOpen(false);
    }, 6000);
  };

  const onLogInFormOpen = () => {
    clearSubmissionNotFoundError();

    setIsRegFormOpen(false);
    setIsLogInFormOpen(true);
  };

  const onSubmissionNotFound = () => {
    setIsEmailSubmissionNotFoundError(true);
    setIsLogInFormOpen(false);
    setIsRegFormOpen(true);
  };

  const onLogInFormClose = () => {
    setIsLogInFormOpen(false);
  };

  const onFormOpen = () => {
    setIsLogInFormOpen(false);
    setIsRegFormOpen(true);
  };

  const onFormClose = () => {
    setIsRegFormOpen(false);
  };

  const clearSubmissionNotFoundError = () => {
    if (isEmailSubmissionNotFoundError) {
      setIsEmailSubmissionNotFoundError(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    document.addEventListener("click", handleClickOutsideLogIn, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
      document.removeEventListener("click", handleClickOutsideLogIn, false);
    };
  });

  const handleClickOutside = event => {
    if (
      regFormRef.current &&
      !regFormRef.current.contains(event.target) &&
      regHeroBannerBtnRef.current &&
      !regHeroBannerBtnRef.current.contains(event.target)
    ) {
      if (!isLogInFormOpen) {
        setIsRegFormOpen(false);
      }
    }
  };

  const handleClickOutsideLogIn = event => {
    if (
      logInHeroBannerBtnRef.current &&
      !logInHeroBannerBtnRef.current.contains(event.target) &&
      thumbImgRef.current &&
      !thumbImgRef.current.contains(event.target) &&
      logInFormRef.current &&
      !logInFormRef.current.contains(event.target)
    ) {
      if (!isRegFormOpen) {
        setIsLogInFormOpen(false);
      }
    }
  };

  return (
    <StyledDarkThemeWrapper>
      <Header
        location={location}
        // onFormOpen={onFormOpen}
        // currentBtnMobileRef={regNavBtnMobileRef}
      />
      <HeroBanner
        location={location}
        onFormOpen={onFormOpen}
        onLogInFormOpen={onLogInFormOpen}
        currentBtnRef={regHeroBannerBtnRef}
        currentLogInBtnRef={logInHeroBannerBtnRef}
        clearSubmissionNotFoundError={clearSubmissionNotFoundError}
        thumbImgRef={thumbImgRef}
      />
      <StyledEGCRegistrationFormWrapper ref={regFormRef} css={isRegFormOpen ? translateXZero : ""}>
        <RegistrationForm
          isEmailSubmissionNotFoundError={isEmailSubmissionNotFoundError}
          onFormClose={onFormClose}
          onLogInFormOpen={onLogInFormOpen}
          location={location}
          clearSubmissionNotFoundError={clearSubmissionNotFoundError}
          closeOpenModalsAfterFiveSeconds={closeOpenModalsAfterFiveSeconds}
        />
      </StyledEGCRegistrationFormWrapper>
      <StyledEGCRegistrationFormWrapper
        ref={logInFormRef}
        css={isLogInFormOpen ? translateXZero : ""}
      >
        <LogInForm
          onSubmissionNotFound={onSubmissionNotFound}
          onFormOpen={onFormOpen}
          onLogInFormClose={onLogInFormClose}
          location={location}
          isLogInFormOpen={isEmailSubmissionNotFoundError}
          closeOpenModalsAfterFiveSeconds={closeOpenModalsAfterFiveSeconds}
        />
      </StyledEGCRegistrationFormWrapper>
      <Speakers />
      <RecapLastYear />
      {/* <FooterReg onFormOpen={onFormOpen} currentBtnRef={regFooterBtnRef} />*/}
      <CopyWriterWithSidebar isDarkMode={true} egcCoc={true} />
    </StyledDarkThemeWrapper>
  );
};

export default EnterpriseConf2022;
